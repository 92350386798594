// @flow
import React from 'react'

type QuizPortabilityFormProps = {
  values: string[];
  setValues: (value: string[]) => {};
  setResult: (value: boolean) => {};
}

const QuizPortabilityForm = ({ values, setValues, setResult }: QuizPortabilityFormProps) => {
  function handleSubmit (event: { preventDefault: () => void }) {
    event.preventDefault()
    setResult(true)
  }

  function handleChange (event: React.ChangeEvent<HTMLInputElement>) {
    const inputChecked = event.currentTarget.checked
    const inputValue = event.currentTarget.value
    if (inputChecked) {
      setValues([ inputValue, ...values ])
    } else {
      const newValues = values
      newValues.splice(0, 1)
      setValues([ ...newValues ])
    }
  }

  return (
    <form className='quiz-portability__form' onSubmit={handleSubmit}>
      <h4 className='fs-24 text-orange--base text-center fw-700 mb-4'>Marques as opções que são verdadeiras:</h4>
      <fieldset>
        <div className='mb-3'>
          <input onChange={handleChange} value={true} disabled={values.includes('false')} type='checkbox' name='portability-1' id='portability-1' />
          <label className='fs-16 fs-md-18 text-gray--600 ml-2 mb-0 lh-22' htmlFor='portability-1'>Prefiro fazer TEDs para quem tem o mesmo banco que o meu.</label>
        </div>
        <div className='mb-3'>
          <input onChange={handleChange} value={true} disabled={values.includes('false')} type='checkbox' name='portability-2' id='portability-2' />
          <label className='fs-16 fs-md-18 text-gray--600 ml-2 mb-0 lh-22' htmlFor='portability-2'>Quando penso em financiamento de imóvel já me imagino endividado.</label>
        </div>
        <div className='mb-3'>
          <input onChange={handleChange} value={true} disabled={values.includes('false')} type='checkbox' name='portability-3' id='portability-3' />
          <label className='fs-16 fs-md-18 text-gray--600 ml-2 mb-0 lh-22' htmlFor='portability-3'>Pago uma taxa toda vez que preciso sacar dinheiro.</label>
        </div>
        <div className='mb-3'>
          <input onChange={handleChange} value={true} disabled={values.includes('false')} type='checkbox' name='portability-4' id='portability-4' />
          <label className='fs-16 fs-md-18 text-gray--600 ml-2 mb-0 lh-22' htmlFor='portability-4'>Recebo meu salário em um banco mas transfiro tudo para outro.</label>
        </div>
        <div className='mb-3'>
          <input onChange={handleChange} value={true} disabled={values.includes('false')} type='checkbox' name='portability-5' id='portability-5' />
          <label className='fs-16 fs-md-18 text-gray--600 ml-2 mb-0 lh-22' htmlFor='portability-5'>Para investir em ações pago um valor mínimo para a corretora.</label>
        </div>
        <div className='mb-3'>
          <input onChange={handleChange} value={true} disabled={values.includes('false')} type='checkbox' name='portability-6' id='portability-6' />
          <label className='fs-16 fs-md-18 text-gray--600 ml-2 mb-0 lh-22' htmlFor='portability-6'>Pago anuidade no meu cartão de crédito.</label>
        </div>
        <div className='mb-3'>
          <input onChange={handleChange} value={true} disabled={values.includes('false')} type='checkbox' name='portability-7' id='portability-7' />
          <label className='fs-16 fs-md-18 text-gray--600 ml-2 mb-0 lh-22' htmlFor='portability-7'>Dinheiro de volta não existe, é lenda urbana.</label>
        </div>
        <div className='mb-3'>
          <input onChange={handleChange} value={false} disabled={values.includes('true')} type='checkbox' name='portability-8' id='portability-8' />
          <label className='fs-16 fs-md-18 text-gray--600 ml-2 mb-0 lh-22' htmlFor='portability-8'>Nenhuma opção é verdadeira.</label>
        </div>
      </fieldset>
      <input disabled={values.length === 0} className='btn btn--orange btn--outline mx-auto my-3 d-block fs-17 fw-700' type='submit' value='Ver meu resultado' />
    </form>
  )
}

export default QuizPortabilityForm
