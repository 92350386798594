import axios from 'axios'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import React, { ChangeEvent, MouseEvent, useState } from 'react'
import { UseFormMethods, useForm } from 'react-hook-form'
import AcceptTerms from 'src/components/AcceptTerms'
import * as URLS from 'src/config/api/Urls'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import errorImg from 'src/assets/images/shared/erro-pig.png'
import successImg from 'src/pages/portabilidade/assets/images/sucesso-checked.svg'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { CloseButton, Container } from './style'

type PortabilityFormProps = {
  closeModal: () => void;
}
interface IFormValues {
  name: string;
  phone: string;
  email: string;
}

function PortabilityForm ({ closeModal }: PortabilityFormProps) {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const sendForm = async (data: IFormValues) => {
    setLoading(true)

    const formData = {
      ...data,
      alliance: 'Portabilidade',
      cpf: '',
      date_of_birth: '',
      desired_value: '',
      email_authorization: true,
      public_body: 'Portabilidade',
      utm: null,
    }

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST}/credito_consignado/data`, formData)
      setSent(true)
      sendDatalayerEvent({
        section: 'dobra_01',
        section_name: 'Você está economizando ou perdendodinheiro?',
        element_name: 'Enviar contato',
        element_action: 'click button',
        step: 'success',
      })
    } catch (e) {
      setError(true)
      setLoading(false)
      sendDatalayerEvent({
        section: 'dobra_01',
        section_name: 'Você está economizando ou perdendodinheiro?',
        element_name: 'Enviar contato',
        element_action: 'click button',
        step: 'error',
      })
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setLoading(false)
  }

  return (
    <>
      { error && (
        <Container className='container d-flex align-items-center'>
          <CloseButton className='border-0 bg-transparent pt-3' onClick={closeModal}>
            <Close width='24' height='24' color='gray' />
          </CloseButton>
          <div className='col-12 text-center'>
            <h3 className='fs-20 lh-24 mb-5'>Ah não! Ocorreu um erro com a sua solicitação...</h3>
            <img src={errorImg} alt='Imagem de erro' />
            <p className='text-gray--600 fs-17 lh-22 pt-3'>
              Verifique a sua conexão com a internet e tente novamente. <br />
              <span
                className='cursor-pointer fw-600' onClick={handleReturn}
                title='Clique aqui para voltar'
              > Clique aqui
              </span> para voltar.
            </p>
          </div>
        </Container>
      )}
      { sent && (
        <Container className='container d-flex align-items-center'>
          <CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
            <Close width='24' height='24' color='gray' />
          </CloseButton>
          <div className='col-12 text-center'>
            <img src={successImg} alt='Imagem de sucesso' />
            <h3 className='fs-30 lh-40 pt-4 text-orange--base'>Contato enviado!</h3>
            <p className='text-gray--700 fs-17 lh-22'>
              Aguarde que um de nossos consultores <span className='d-md-block'>entrará em contato com você.</span>
            </p>
          </div>
        </Container>
      )}
      <Container className={`container ${ error || sent ? 'd-none' : 'd-flex'} align-items-center`}>
        <CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
          <Close width='24' height='24' color='gray' />
        </CloseButton>
        <div className='row'>
          <div className='col-12 text-center py-4 pt-lg-0'>
            <h3 className='fs-30 mb-4 lh-35 text-orange--base'>Quero valorizar <span className='d-block'>meu dinheiro!</span></h3>
            <p className='fs-17 lh-22 mb-0 text-gray--700'>Informe seus contatos e venha fazer parte da revolução bancária.</p>
          </div>
          <div className='col-12'>
            <form className='form--default' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='col-12 pb-4 d-flex flex-column px-0 px-md-3'>
                  <label htmlFor='alliance' className='fs-14 lh-18 fw-600'>Modalidade de Portabilidade</label>
                  <input
                    name='alliance'
                    id='alliance'
                    type='text'
                    value='Portabilidade de Empréstimo Consignado'
                    disabled
                  />
                </div>
                <div className='col-12 pb-4 d-flex flex-column px-0 px-md-3'>
                  <label htmlFor='name' className='fs-14 lh-18 fw-600'>Nome</label>
                  <input
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    name='name'
                    id='name'
                    type='text'
                    maxLength={100}
                    placeholder='Digite o nome completo'
                  />
                  { errors.name && <p className='fs-12 text-red--base mb-0'>{errors.name.message}</p> }
                </div>
                <div className='col-12 pb-4 d-flex flex-column px-0 px-md-3'>
                  <label htmlFor='phone' className='fs-14 lh-18 fw-600'>Celular</label>
                  <input
                    ref={register({
                      required: 'Telefone obrigatório',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name='phone'
                    id='phone'
                    type='tel'
                    placeholder='(00) 0000-0000'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('phone', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  { errors.phone && <p className='fs-12 text-red--base mb-0'>{errors.phone.message}</p> }
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3'>
                  <label htmlFor='email' className='fs-14 lh-18 fw-600'>E-mail</label>
                  <input
                    ref={register({
                      required: 'E-mail obrigatório',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu email'
                  />
                  { errors.email && <p className='fs-12 text-red--base mb-0'>{errors.email.message}</p> }
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3'>
                  <AcceptTerms accept={accept} setAccept={setAccept} name='quero-valorizar-meu-dinheiro' />
                </div>
                <div className='col-12 pt-1'>
                  <button
                    type='submit' title='Enviar contato' disabled={!accept || loading}
                    className='text-white text-none fs-16 btn btn-orange--extra btn--lg rounded-2 mx-auto quero-mudar'
                  >
                    {loading ? 'Enviando...' : 'Enviar contato'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default PortabilityForm
