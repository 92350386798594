import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { Checkbox } from './style'

type AcceptTermsProps = {
  accept: boolean;
  setAccept: Function;
  name: string;
  label: string;
  finalLabel?: string;
  styles?: string;
  linkColor?: string;
  dataLayer?: IDataLayerParams;
  acceptText?: string;
  acceptLink?: string;
}
function AcceptTerms ({ accept, setAccept, name, label, finalLabel, styles, linkColor, dataLayer, acceptText, acceptLink }: AcceptTermsProps) {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://inter.co/politica-de-privacidade/privacidade/'

  function handleCheck () {
    setAccept(!accept)
  }

  return (
    <Checkbox>
      <input
        name={name}
        value={accept.toString()}
        id={name}
        type='checkbox'
        className='form-input-check'
        onChange={() => handleCheck()}
        checked={accept}
      />
      <label className={`form-label-check ${styles}`} htmlFor={name}>
        {label}
        <a
          className='fw-700'
          title='Acessar Política de Privacidade do Inter'
          href={url}
          target='_blank' rel='noreferrer'
          style={{ color: linkColor ? linkColor : '#FF8700' }}
          onClick={() => {
            dataLayer && sendDatalayerEvent({
              ...dataLayer,
              section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
              section_name: dataLayer ? dataLayer.section_name : '',
              element_action: 'click button',
              element_name: 'Política de Privacidade',
              element_previous: dataLayer ? `${dataLayer.element_name}, ${dataLayer.section}` : '',
              redirect_url: url,
            })
          }}
        > <u>Política de Privacidade</u>
        </a> do Inter. {acceptText} <a href={acceptLink} title={finalLabel} target='_blank' className={styles}><strong><u>{finalLabel}</u></strong></a>
      </label>
    </Checkbox>
  )
}

AcceptTerms.defaultProps = {
  name: 'aceite',
  label: 'Autorizo o contato e estou de acordo com a',
  styles: 'fs-14 lh-17',
}

export default AcceptTerms
