import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDomReady from 'src/hooks/window/useDomReady'
import PortabilityApp from 'src/components/PortabilityApp'
import PortabilityForm from 'src/components/UI/Forms/PortabilityForm'
import { Modal } from 'src/components/Modal'

import Header from './sections/header/_header'
import SeuDinheiroValeMuito from './sections/sue-dinheiro-vale-muito/_seu-dinheiro-vale-muito'
import PortabilidadeNumero from './sections/portabilidade-numero/_portabilidade-numero'
import PortabilidadeSalario from './sections/portabilidade-de-salario/_portabilidade-de-salario'
import PortabilidadeInvestimento from './sections/portabilidade-de-investimentos/_portabilidade-de-investimentos'
import PortabilidadePrevidenciaPrivada from './sections/portabilidade-de-previdencia-privada/_portabilidade-de-previdencia-privada'
import PortabilidadeFinanciamentoImobiliario from './sections/portabilidade-financiamento-imobiliario/_portabilidade-financiamento-imobiliario'
import PortabilidadeEmprestimoConsignado from './sections/portabilidade-emprestimo-consignado/_portabilidade-emprestimo-consignado'
import PortabilidadeMaquininhaCartao from './sections/portabilidade-maquininha-de-cartao/_portabilidade-maquininha-de-cartao'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const Portabilidade = () => {
  const domReady = useDomReady()
  const [ portabilityAppOpen, setPortabilityAppOpen ] = useState(false)
  const [ portabilityFormOpen, setPortabilityFormOpen ] = useState(false)

  const portabilityAppModal = domReady && (
    <Modal isModalOpen={portabilityAppOpen} setIsModalOpen={setPortabilityAppOpen} locationToRender={document.body}>
      <PortabilityApp closeModal={setPortabilityAppOpen} />
    </Modal>
  )
  const portabilityFormModal = domReady && (
    <Modal isModalOpen={portabilityFormOpen} setIsModalOpen={setPortabilityFormOpen} locationToRender={document.body}>
      <PortabilityForm closeModal={() => setPortabilityFormOpen(false)} />
    </Modal>
  )
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {portabilityAppModal}
        {portabilityFormModal}
        <Header />
        <SeuDinheiroValeMuito />
        <PortabilidadeNumero />
        <PortabilidadeSalario setPortabilityAppOpen={setPortabilityAppOpen} />
        <PortabilidadeInvestimento />
        <PortabilidadePrevidenciaPrivada />
        <PortabilidadeFinanciamentoImobiliario />
        <PortabilidadeEmprestimoConsignado setPortabilityFormOpen={setPortabilityFormOpen} />
        <PortabilidadeMaquininhaCartao />
      </Layout>
    </Wrapper>
  )
}

export default Portabilidade
