import React, { memo, useState, useLayoutEffect } from 'react'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import AppInter from 'src/pages/portabilidade/assets/images/muda-pro-inter.png'
import LogoMudaProInter from 'src/pages/portabilidade/assets/images/logo-muda-pro-inter-orange.svg'
import IconStore from 'src/components/UI/MarkdownIcon'
import useWidth from 'src/hooks/window/useWidth'
import { Container, CloseButton, Smartphone } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type PortabilityAppPros = {
  closeModal: Function;
}

function PortabilityApp ({ closeModal }: PortabilityAppPros) {
  const [ url, setUrl ] = useState('')
  const [ target, setTarget ] = useState('')
  const WIDTH_MD = 769
  const windowWidth = useWidth(200)
  const [ sendDatalayerEvent ] = useDataLayer()

  useLayoutEffect(() => {
    setUrl(windowWidth < WIDTH_MD ? 'bancointer://portSalario' : 'https://internetbanking.bancointer.com.br/servicos/portabilidade.jsf')
    setTarget(windowWidth < WIDTH_MD ? '_self' : '_blanck')
  }, [ windowWidth ])

  return (
    <Container className='container'>
      <div className='row text-center justify-content-center align-items-center'>
        <div className='col-12'>
          <CloseButton className='border-0 bg-transparent' onClick={() => closeModal(false)}>
            <Close width='24' height='24' color='gray' />
          </CloseButton>
          <div className='col-12 col-md-11 mx-auto col-lg-8 mr-lg-0 offset-lg-2 mb-4 mb-lg-5 px-lg-5'>
            <div className='col-8 col-md-6 col-lg-5 mx-auto mt-5 px-lg-0'>
              <img src={LogoMudaProInter} alt='Logo muda pro Banco Inter' />
            </div>
            <p className='text-gray--700 mt-4 lh-22'>
              Com o salário caindo direto na sua conta digital, você controla o seu dinheiro de <strong>um jeito simples e sem surpresas.</strong>
            </p>
            <p className='text-gray--700 mt-3 lh-22'>
              E o melhor: tem uma <strong>plataforma completa</strong> de serviços financeiros e não financeiros para o que precisar.
            </p>
          </div>
          <div className='row mx-0 align-items-center'>
            <div className='col-12 pt-4 bg-gray'>
              <Smartphone className='col-12 col-lg-4 d-none d-lg-block'>
                <img src={AppInter} alt='App Banco Inter' />
              </Smartphone>
              <div className='col-12 col-lg-8 offset-lg-4 px-0 mb-4 mt-lg-0'>
                <div className='row mx-0'>
                  <div className='col-12'>
                    <h3 className='text-orange--base fs-30 fw-900'>Gostou?</h3>
                  </div>
                  <div className='col-12 col-lg-6 pl-lg-0'>
                    <p className='fs-md-14 lh-25 lh-md-18 text-gray--700 mb-3 mb-lg-4'>
                      <span className='d-block'>Se você já é correntista, solicite</span> a portabilidade pelo App.
                    </p>
                    <a
                      href={url} target={target}
                      className='btn-quero-mudar bt btn-lg fw-600 fs-17 lh-22 text-orange--base btn--outline btn--orange d-block mx-auto'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          section_name: 'Com o salário caindo direto na sua conta digital, você controla o seu dinheiro de um jeito simples e sem surpresas.',
                          element_action: 'click button',
                          element_name: 'Quero mudar pro Inter!',
                          redirect_url: url,
                        })
                      }}
                    >
                      Quero mudar pro Inter!
                    </a>
                  </div>
                  <div className='col-12 col-lg-6 my-4 mt-lg-0 pl-lg-0'>
                    <p className='fs-md-14 lh-25 lh-md-18 text-gray--700 mt-2 mb-3 mt-lg-0 mb-lg-4'>
                      Ainda não tem sua conta digital? <strong className='d-block'> Então baixe o app agora.</strong>
                    </p>
                    <div className='d-flex justify-content-center'>
                      <div className='mr-3'>
                        <a
                          href='https://play.google.com/store/apps/details?id=br.com.intermedium'
                          target='_blank'
                          rel='noreferrer'
                          aria-label='Google Play'
                          title='Baixe na Google Play'
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_01',
                              section_name: 'Com o salário caindo direto na sua conta digital, você controla o seu dinheiro de um jeito simples e sem surpresas.',
                              element_action: 'click button',
                              element_name: 'Baixe na Google Play',
                              redirect_url: 'https://play.google.com/store/apps/details?id=br.com.intermedium',
                            })
                          }}
                        >
                          <IconStore width='137' height='40.43' icon='play-store-color-pt' directory='stores' />
                        </a>
                      </div>
                      <div>
                        <a
                          href='https://itunes.apple.com/br/app/banco-inter/id839711154'
                          target='_blank'
                          rel='noreferrer'
                          aria-label='App Store'
                          title='Baixe na Apple Store'
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_01',
                              section_name: 'Com o salário caindo direto na sua conta digital, você controla o seu dinheiro de um jeito simples e sem surpresas.',
                              element_action: 'click button',
                              element_name: 'Baixe na Apple Store',
                              redirect_url: 'https://itunes.apple.com/br/app/banco-inter/id839711154',
                            })
                          }}
                        >
                          <IconStore width='135.56' height='40' icon='app-store-color-pt' directory='stores' />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

PortabilityApp.defaultProps = {
  open: false,
  setOpen: () => {},
}

export default memo(PortabilityApp)
