import React, { useState } from 'react'
import { ContentHeader } from './style'
import QuizPortability from 'src/components/QuizPortability'

import PortabilityApp from 'src/components/PortabilityApp'
import PortabilityForm from 'src/components/UI/Forms/PortabilityForm'
import { Modal } from 'src/components/Modal'

// hooks
import useDomReady from 'src/hooks/window/useDomReady'

const Header = () => {
  const domReady = useDomReady()
  const [ portabilityAppOpen, setPortabilityAppOpen ] = useState(false)
  const [ portabilityFormOpen, setPortabilityFormOpen ] = useState(false)

  const portabilityAppModal = domReady && (
    <Modal isModalOpen={portabilityAppOpen} setIsModalOpen={setPortabilityAppOpen} locationToRender={document.body}>
      <PortabilityApp closeModal={() => setPortabilityAppOpen(false)} />
    </Modal>
  )
  const portabilityFormModal = domReady && (
    <Modal isModalOpen={portabilityFormOpen} setIsModalOpen={setPortabilityFormOpen} locationToRender={document.body}>
      <PortabilityForm closeModal={() => setPortabilityFormOpen(false)} />
    </Modal>
  )

  return (
    <>
      {portabilityAppModal}
      {portabilityFormModal}
      <ContentHeader className='d-flex align-items-center position-relative'>
        <div className='container'>
          <QuizPortability handleModals={{ setPortabilityAppOpen, setPortabilityFormOpen }} />
        </div>
      </ContentHeader>
    </>
  )
}

export default Header
