import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    margin: 0 auto;
    width: 296px;

    @media ${device.tablet} {
      width: 336px;
    }

    @media ${device.desktopLG} {
      width: auto;
    }

    @media ${device.desktopXL} {
      width: 456px;
      margin: inherit;
      float: right;
    }
  }
`
