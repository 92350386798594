import React from 'react'
import Img from 'gatsby-image'
import Icon from 'src/components/UI/MarkdownIcon'
import ToolTip from '../../ToolTip/_ToolTip'

import usePageQuery from './../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const PortabilidadeInvestimento = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='portabilidade-de-investimentos' className='pb-5 pb-lg-0'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-4 col-lg-5 col-xl-6 pt-lg-5 pb-xl-0 pt-xl-0  d-none d-lg-block'>
            <Img fluid={data.portabilidadeDeInvestimentos.fluid} alt='Portabilidade de investimentos' />
          </div>
          <div className='order-md-2 col-12 col-md-6 col-lg-7 col-xl-6 px-0'>
            <h2 className='fs-24 lh30 fs-lg-40 lh-lg-48 fw-600 text-orange--base mb-4 pt-5 pt-lg-0'><span className='d-block d-lg-inline'>Portabilidade de</span> Investimentos</h2>
            <p className='fs-16 fs-lg-18 lh-19 lh-lg-22'>Escolha uma corretora que combina <span className='d-block d-md-inline'>com você! Na Inter Invest tem o produto</span> ideal <span className='d-md-block d-lg-inline'>para o seu perfil:</span></p>
            <div className='row mt-4 align-items-center'>
              <div className='col-12 col-lg-6 pr-4 order-lg-2'>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Facilidade para reinvestir o seu<span className='d-md-block d-lg-inline'> dinheiro</span></p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Home Broker com taxa zero</p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>
                    <span>
                      Possibilidade Pontos Loop nos Fundos
                    </span>
                    <span className='position-relative'> <ToolTip /></span>
                  </p>
                </div>
              </div>
              <div className='col-12 col-lg-6 pr-4 order-lg-1'>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Toda sua carteira disponível na Inter Invest</p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p
                    className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'
                  >Zero taxa de custódia sobre seus
                    <a
                      href='https://inter.co/pra-voce/investimentos/'
                      target='_blank'
                      rel='noreferrer'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_05',
                          section_name: 'Portabilidade de Investimentos',
                          element_action: 'click button',
                          element_name: 'investimentos',
                          redirect_url: 'https://inter.co/pra-voce/investimentos/',
                        })
                      }}
                    >
                      <span className='text-orange--extra fw-600'>investimentos</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className='order-md-3 col-12 col-lg-12 mt-3 pr-4 mt-lg-0 mt-lg-4 text-center d-none d-lg-block'>
              <a
                className='fw-600 btn-lg btn--outline btn--orange mb-4'
                href='https://blog.inter.co/como-transferir-investimentos-entre-corretoras/?utm_source=SITE&utm_medium=LP_PORTABILIDADE&utm_campaign=BOTAO_INVESTIMENTOS'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Portabilidade de Investimentos',
                    element_action: 'click button',
                    element_name: 'Quero mudar pro Inter!',
                    redirect_url: 'https://blog.inter.co/como-transferir-investimentos-entre-corretoras/?utm_source=SITE&utm_medium=LP_PORTABILIDADE&utm_campaign=BOTAO_INVESTIMENTOS',
                  })
                }}
              >
                Quero mudar pro Inter!
              </a>
            </div>
          </div>
          <div className='col-md-6 col-lg-5 pt-2 pb-3 d-lg-none order-md-1'>
            <Img fluid={data.portabilidadeDeInvestimentos.fluid} alt='Portabilidade de investimentos' />
          </div>
          <div className='order-md-3 col-12 col-lg-6 offset-lg-5 mt-3 pr-4 mt-lg-0 text-center d-block d-lg-none'>
            <a
              className='fw-600 btn-lg btn--outline btn--orange mb-4'
              href='https://blog.inter.co/como-transferir-investimentos-entre-corretoras/?utm_source=SITE&utm_medium=LP_PORTABILIDADE&utm_campaign=BOTAO_INVESTIMENTOS'
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  section_name: 'Portabilidade de Investimentos',
                  element_action: 'click button',
                  element_name: 'Quero mudar pro Inter!',
                  redirect_url: 'https://blog.inter.co/como-transferir-investimentos-entre-corretoras/?utm_source=SITE&utm_medium=LP_PORTABILIDADE&utm_campaign=BOTAO_INVESTIMENTOS',
                })
              }}
            >
              Quero mudar pro Inter!
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PortabilidadeInvestimento
