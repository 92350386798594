import React from 'react'
import Img from 'gatsby-image'
import Icon from 'src/components/UI/MarkdownIcon'

import usePageQuery from './../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type modalProps = {
  setPortabilityAppOpen: Function;
}

const PortabilidadeSalario = ({ setPortabilityAppOpen }: modalProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='portabilidade-de-salario' className='bg-gray py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 px-0 pl-lg-3'>
            <h2 className='fs-24 fs-lg-40 hl-30 hl-lg-48 fw-600 pb-md-4 text-orange--base'>
              <span className='d-lg-block'>Portabilidade de </span>Salário
            </h2>
            <p className='fs-16 fs-lg-18 lh-19 lh-lg-22'>
              Receba seu salário em uma conta digital
              <span className='d-md-block d-lg-inline'> totalmente gratuita e aproveite os </span>
              benefícios de ter um banco completo:
            </p>
            <div className='row mt-4 mt-lg-5 align-items-center'>
              <div className='col-12 col-lg-6 pr-4'>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 lh-19 lh-lg-22 ml-2 mb-0'>Conta 100% gratuita</p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 lh-19 lh-lg-22 ml-2 mb-0'>TEDs ilimitadas</p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 lh-19 lh-lg-22 ml-2 mb-0'>
                    <span className='d-block d-xl-none'>Saques gratuito
                      <span className='d-lg-block'> nas redes 24</span> Horas
                      <span className='d-md-block d-lg-inline'>e Saque e
                        <span className='d-lg-block'>  Pague</span>
                      </span>
                    </span>
                    <span className='d-none d-xl-block'>Saques gratuito nas <span className='d-xl-block'>redes 24 Horas e Saque e</span> Pague</span>
                  </p>
                </div>
              </div>
              <div className='col-12 col-lg-6 pr-4 pb-lg-5'>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 lh-19 lh-lg-22 ml-2 mb-0'>Shopping com cashback</p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 lh-19 lh-lg-22 ml-2 mb-0'>Inter Invest</p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 lh-19 ml-2 mb-0'>Cartão MasterCard sem <span className='d-block d-lg-inline'> anuidade</span></p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-6 pt-2 pb-3'>
            <Img fluid={data.portabilidadeDeSalario.fluid} alt='Portabilidade de Salário' />
          </div>
          <div className='col-12 mt-3 mt-md-4 mt-lg-0 text-center'>
            <a
              onClick={() => {
                setPortabilityAppOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: 'Portabilidade de Salário',
                  element_action: 'click button',
                  element_name: 'Quero mudar pro Inter!',
                })
              }}
              className='fw-600 btn-lg btn--outline btn--orange'
              href='#'
            >
              Quero mudar pro Inter!
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PortabilidadeSalario
