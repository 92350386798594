import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Options = styled.div`
  overflow-x: scroll;
  min-height: 60px;

  @media ${device.desktopLG} {
    overflow: auto;
  }

  .grid-hidden {
    overflow: hidden;
    width: 1500px;

    @media ${device.desktopXXL} {
      width: 100%;
    }

    > a {
      background: #FFF2EA;
      border-radius: 29px;
      font-size: 14px;
      line-height: 17px;
      margin-right: 12px;
      padding: 10px 20px;
      width: 260px;
      min-height: 71px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:first-child {

        @media ${device.desktopLG} {
          line-height: 17px;
        }
      }

      @media ${device.desktopLG} {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`
