import React from 'react'
import Img from 'gatsby-image'
import Icon from 'src/components/UI/MarkdownIcon'

import { ImgSize } from './style'

import usePageQuery from './../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const PortabilidadePrevidenciaPrivada = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='portabilidade-de-previdencia-privada' className='bg-gray--500 py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6 px-md-0 pl-lg-3'>
            <h2 className='fs-24 fs-lg-40 hl-29 lh-lg-48 fw-600 text-orange--base mb-4 pt-lg-5'><span className='d-block'>Portabilidade de</span> Previdência Privada</h2>
            <p className='fs-16 fs-lg-18 lh-19 lh-lg-22'>
              <span className='d-block d-xl-none'>Os melhores fundos do mercado para você <span className='d-md-block d-lg-inline'> planejar o seu futuro. Confira as vantagens</span> e faça a portabilidade:</span>
              <span className='d-none d-xl-block'><span className='d-xl-block'>Os melhores fundos do mercado para você planejar o seu </span>futuro. Confira as vantagens e faça a portabilidade:</span>
            </p>
            <div className='row mt-5 mt-lg-5 align-items-center'>
              <div className='col-12 col-lg-6'>
                <div className='d-flex align-items-center mb-3 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Portabilidade sem <span className='d-block d-lg-inline'><span className='d-lg-block'>complicações. Nós</span> cuidamos </span> de todo o <span className='d-lg-block'> processo para <span className='d-block d-lg-inline'>você.</span></span></p>
                </div>
                <div className='d-flex align-items-center mb-3 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>
                    <span className='d-block d-xl-none'>Opções de fundos PGBL e <span className='d-block d-xl-inline'>VGBL.</span></span>
                    <span className='d-none d-xl-block'>Opções de fundos PGBL <span className='d-xl-block'>e VGBL.</span></span>
                  </p>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='d-flex align-items-center mb-3 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>
                    <span className='d-block d-xl-none'><span className='d-lg-block d-xl-inline'>Flexibilidade no</span> período e <span className='d-block d-lg-inline'>no tamanho <span className='d-lg-block d-xl-inline'>dos aportes.</span></span></span>
                    <span className='d-none d-xl-block'>Flexibilidade no período e <span className='d-xl-block'>no tamanho dos aportes.</span></span>
                  </p>
                </div>
                <div className='d-flex align-items-center mb-3 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>
                    <span className='d-block d-xl-none'>Conte com a nossa <span className='d-block'>equipe para escolher o </span> melhor fundo para<span className='d-lg-block'> seus <span className='d-block d-lg-inline'>objetivos.</span></span></span>
                    <span className='d-none d-xl-block'>Conte com a nossa <span className='d-xl-block'>equipe para escolher o </span> melhor fundo para seus <span className='d-xl-block'>objetivos.</span></span>
                  </p>
                </div>
              </div>
              <div className='col-12 mt-4 d-none d-lg-block text-center text-lg-right'>
                <button
                  className='fw-600 btn-lg btn--outline btn--orange mb-4'
                >
                  <a
                    href='https://inter.co/atendimento-chat/?tipoAssunto=divulgacao&assunto=portabilidade-previdencia'
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_06',
                        section_name: 'Portabilidade de Previdência Privada',
                        element_action: 'click button',
                        element_name: 'Quero mudar pro Inter!',
                        redirect_url: 'https://inter.co/atendimento-chat/?tipoAssunto=divulgacao&assunto=portabilidade-previdencia',
                      })
                    }}
                  >
                    Quero mudar pro Inter!
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-5 pt-2 pt-md-5 pb-3'>
            <ImgSize>
              <Img fluid={data.portabilidadeDePrevidenciaPrivada.fluid} alt='Portabilidade de Previdência Privada' />
            </ImgSize>
          </div>
          <div className='col-12 col-lg-6 mt-3 text-center d-lg-none'>
            <button
              className='fw-600 btn-lg btn--outline btn--orange mb-4'
            >
              <a
                href='https://inter.co/atendimento-chat/?tipoAssunto=divulgacao&assunto=portabilidade-previdencia'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_06',
                    section_name: 'Portabilidade de Previdência Privada',
                    element_action: 'click button',
                    element_name: 'Quero mudar pro Inter!',
                    redirect_url: 'https://inter.co/atendimento-chat/?tipoAssunto=divulgacao&assunto=portabilidade-previdencia',
                  })
                }}
              >
                Quero mudar pro Inter!
              </a>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PortabilidadePrevidenciaPrivada
