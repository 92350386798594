import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { white, orange } from 'src/styles/colors'

export const ContentHeader = styled.section`
  background: ${orange.extra};
  min-height: calc(100vh - 64px);

  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
  }

  .button-cta {
    background: ${white};
    border: none;
    color: ${orange.extra};
    font-size: 16px;
    min-width: 246px;
    padding: 10px 20px;

    @media ${device.tablet} {
      min-width: 275px;
      padding: 19px 50px;
      font-size: 28px;
    }

    &:hover {
      background-color: #FC6801;
      color: white;
    }
  }

  >.container {
    padding-top: 30px;
    
    @media ${device.desktopLG} {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
`
