import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Container = styled.div`
  background: ${white};
  min-height: 100vh;
  padding: 40px 20px;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    max-width: 596px;
    min-height: 80vh;
    padding: 40px 100px;
    top: 10vh;
  }

  @media (min-width: ${breakpoints.xxl}) {
    min-height: 687px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;
`
