import React from 'react'
import Img from 'gatsby-image'
import Icon from 'src/components/UI/MarkdownIcon'

import { Link } from 'gatsby'

import usePageQuery from './../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const PortabilidadeNumero = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='portabilidade-de-numero' className='pb-5 pt-md-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-4 col-lg-6 d-none d-lg-block'>
            <Img fluid={data.portabilidadeDeNumero.fluid} alt='Portabilidade de número' />
          </div>
          <div className='order-2 col-12 col-md-6 px-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh30 lh-lg-40 lh-xl-50 fw-600 text-orange--base mb-4'>Portabilidade de Número</h2>
            <p className='fs-16 fs-xl-18 lh-19 lh-xl-22'>
              Com a Inter Cel, você muda de operadora sem perder o seu número e tem a maior cobertura de internet móvel do país.
            </p>
            <div className='row mt-4 align-items-center'>
              <div className='col-12 col-xl-6 pr-4'>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Portabilidade 100% <span className='d-xl-block'>digital</span></p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Pontos Loop em todos os planos</p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Até 24GB de bônus de internet</p>
                </div>
              </div>
              <div className='col-12 col-xl-6 pr-4 pb-xl-5 mb-xl-4'>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Apps que não gastam <span className='d-xl-block'>sua internet</span></p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Planos a partir de R$ 25</p>
                </div>
              </div>
              <div className='order-3 col-12 col-lg-12 mt-3 pr-4 mt-lg-0 mt-xl-4 text-left d-none d-lg-block'>
                <a
                  className='fw-600 btn-lg btn--outline btn--orange mb-4'
                  href='https://inter.co/intercel/?utm_source=SITE&utm_medium=LP_PORTABILIDADE&utm_campaign=BOTAO_INTERCEL'
                  target='_blank' rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      section_name: 'Portabilidade de Número',
                      element_action: 'click button',
                      element_name: 'Quero mudar pro Inter!',
                      redirect_url: 'https://inter.co/intercel/?utm_source=SITE&utm_medium=LP_PORTABILIDADE&utm_campaign=BOTAO_INTERCEL',
                    })
                  }}
                >
                  Quero mudar pro Inter!
                </a>
              </div>
            </div>
          </div>
          <div className='col-md-6 pt-2 pb-3 d-lg-none order-1'>
            <Img fluid={data.portabilidadeDeNumero.fluid} alt='Portabilidade de número' />
          </div>
          <div className='order-3 col-12 col-lg-6 offset-lg-5 mt-3 pr-4 mt-lg-0 text-center d-block d-lg-none'>
            <Link
              className='fw-600 btn-lg btn--outline btn--orange mb-4'
              to='https://inter.co/intercel/?utm_source=SITE&utm_medium=LP_PORTABILIDADE&utm_campaign=BOTAO_INTERCEL'
              target='_blank' rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_03',
                  section_name: 'Portabilidade de Número',
                  element_action: 'click button',
                  element_name: 'Quero mudar pro Inter!',
                  redirect_url: 'https://inter.co/intercel/?utm_source=SITE&utm_medium=LP_PORTABILIDADE&utm_campaign=BOTAO_INTERCEL',
                })
              }}
            >Quero mudar pro Inter!
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PortabilidadeNumero
