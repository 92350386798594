import React, { useState } from 'react'

import useWidth from 'src/hooks/window/useWidth'
import Help from 'inter-frontend-svgs/lib/orangeds/MD/help'

import { orange } from 'src/styles/colors'
import { Tooltip, ButtonToolTip } from './style'

const ToolTip = () => {
  const [ open, setOpen ] = useState(false)

  const WIDTH_LG = 1024
  const windowWidth = useWidth(300)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      { windowWidth >= WIDTH_LG
        ? (
          <ButtonToolTip onClick={() => handleClick()} className='cursor-pointer'>
            <Help color={orange.extra} width={16} height={16} />
          </ButtonToolTip>
          )
        : (
          <ButtonToolTip onClick={() => setOpen(true)}>
            <Help color={orange.extra} width={16} height={16} />
          </ButtonToolTip>)
      }

      {open &&
        <Tooltip className='tooltip'>
          <p className='fs-12 lh-15 fw-700 text-white mb-1'>Pontos Loop nos Fundos de Investimento</p>
          <p className='fs-12 lh-15 fw-400 text-white text-left'>Clientes Black têm direito a 20% e clientes WIN a 40% do rebate em Pontos Loop direto na conta. Para receber o benefício, é necessário que a soma dos potenciais Pontos Loops de todos os fundos investidos totalize ao menos 400 pontos*.</p>
          <p className='fs-12 lh-15 fw-400 text-white text-left'>*Atualizado em 04/2024</p>
          <span className='d-block fs-12 lh-15 fw-700 text-orange--extra text-right cursor-pointer' onClick={() => setOpen(false)} title='Entendi'>Entendi</span>
        </Tooltip>
      }
    </>
  )
}

export default ToolTip
