import styled from 'styled-components'
import { orange } from 'src/styles/colors'

export const Button = styled.button`
  height: 48px;
  border: 2px solid ${orange.extra};
  background: none;
  color:  ${orange.extra};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

`
