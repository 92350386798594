import React from 'react'

// Components
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

// Assets
import emojiSmile from 'src/pages/portabilidade/assets/images/emoji-smile.svg'
import emojiFlyMoney from 'src/pages/portabilidade/assets/images/emoji-fly-money.svg'

// Data
import QuizPortabilityResultJSON from './data/quiz-portability-result-slider.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ItemsProps = {
  title: string;
  description: string;
  type: string;
  url: string ;
}

type QuizPortabilityResultProps = {
  values: string[];
  handleModals: string;
}

const QuizPortabilityResult = ({ values, handleModals }: QuizPortabilityResultProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const customText = values.length > 1 ? 'suas escolhas estão' : 'sua escolha está'

  function handleClick (url: string) {
    handleModals[url](true)
  }

  return (
    <div>
      {
        values.includes('true')
          ? (
            <>
              <h2 className='fs-22 lh-29 lh-lg-48 fs-lg-40 fw-600 text-orange--base mb-4'>
                Opa, parece que {customText} te fazendo perder dinheiro!
                <img src={emojiFlyMoney} alt='Emojy Money' className='mb-2 ml-3' />
              </h2>
              <p className='text-gray--light'>
                Mas calma, dá pra fazer portabilidade de todos seus serviços para o Banco Inter <strong>sem pagar nada, economizar e ainda ganhar dinheiro de volta:</strong>
              </p>
              <p className='text-gray--light mb-0'>
                O movimento <strong>#MudaProInter</strong> veio pra te ajudar a salvar aquela grana no final do mês.
                Todos os serviços que você paga para usar em outro banco são <strong>totalmente gratuitos</strong> aqui ou com <strong>as melhores taxas.</strong> Tá esperando o que pra mudar de vez?
              </p>
            </>
          )
          : (
            <>
              <h2 className='fs-22 lh-29 lh-lg-48 fs-lg-40 fw-600 text-orange--base mb-4'>
                Mandou bem! Parece que você já faz as melhores escolhas para o seu dinheiro.
                <img src={emojiSmile} alt='Emojy Money' className='mb-2 ml-3' />
              </h2>
              <p className='text-gray--light'>
                Mas sabia que dá pra fazer portabilidade de todos seus serviços para o Banco Inter <strong>sem pagar nada e economizar ainda mais?</strong>
              </p>
              <p className='text-gray--light'>
                O movimento <strong>#MudaProInter</strong> veio pra te ajudar a salvar aquela grana no final do mês. Todos os serviços que você
                talvez pague para usar em outro banco são <strong>totalmente gratuitos aqui ou com as melhores taxas.</strong>
              </p>
            </>
          )
      }
      <DefaultCarousel
        sm={{ items: 1, partialVisibilityGutter: 40 }}
        xl={{ items: 3, slidesToSlide: 1 }}
      >
        {
          QuizPortabilityResultJSON.map((item: ItemsProps) => (
            <div key={item.title} className='bg-grayscale--100 rounded-5 h-100 mx-2 p-3 d-flex flex-column justify-content-between'>
              <p className='fs-17 fw-600 lh-22 text-left text-gray--light'>{item.title}</p>
              <p className='fs-14 lh-18 mt-2 text-gray--light'>{item.description}</p>
              <div className='w-100 mt-lg-2 text-center d-flex'>
                {
                  item.type === 'link'
                    ? (
                      <a
                        className='fs-16 fw-400 text-center text-uppercase mb-0'
                        href={item.url}
                        target='_blank'
                        rel='noreferrer'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_01',
                            section_name: values.includes('true')
                            ? `Opa, parece que ${customText} te fazendo perder dinheiro!`
                            : 'Mandou bem! Parece que você já faz as melhores escolhas para o seu dinheiro.',
                            element_action: 'click button',
                            element_name: `Quero conhecer + ${item.title}`,
                            redirect_url: item.url,
                          })
                        }}
                      >
                        Quero conhecer
                      </a>
                    )
                    : (
                      <button
                        onClick={() => {
                          handleClick(item.url)
                          sendDatalayerEvent({
                            section: 'dobra_01',
                            section_name: values.includes('true')
                            ? `Opa, parece que ${customText} te fazendo perder dinheiro!`
                            : 'Mandou bem! Parece que você já faz as melhores escolhas para o seu dinheiro.',
                            element_action: 'click button',
                            element_name: `Quero conhecer + ${item.title}`,
                            redirect_url: item.url,
                          })
                        }}
                        className='fs-16 fw-400 text-center text-orange--base text-uppercase mb-0 bg-transparent border-0 p-0'
                      >
                        Quero conhecer
                      </button>
                    )
                }
              </div>
            </div>
          ))
        }
      </DefaultCarousel>
    </div>
  )
}

QuizPortabilityResult.defaultProps = {
  title: '',
  description: '',
}

export default QuizPortabilityResult
