import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      portabilidadeDeSalario: imageSharp(fluid: {originalName: { regex: "/portabilidadeDeSalario/" }}) {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeDeInvestimentos: imageSharp(fluid: {originalName: { regex: "/portabilidadeDeInvestimentos/" }}) {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeDeNumero: imageSharp(fluid: {originalName: { regex: "/portebilidadeDeNumero/" }}) {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeDePrevidenciaPrivada: imageSharp(fluid: {originalName: { regex: "/mockupTelaApp/" }}) {
        fluid(maxWidth: 358, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeDeFinanciamento: imageSharp(fluid: {originalName: { regex: "/portabilidadeDeFinanciamento/" }}) {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeDeEmprestimoConsignado: imageSharp(fluid: {originalName: { regex: "/portabilidadeDeEmprestimoConsignado/" }}) {
        fluid(maxWidth: 395, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      portabilidadeDeDomicilioBancario: imageSharp(fluid: {originalName: { regex: "/portabilidadeDeDomicilioBancario/" }}) {
        fluid(maxWidth: 480, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
