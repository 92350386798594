import React from 'react'
import { Options } from './style'

// component
import ScrollTo from 'src/components/ScrollTo'

// Assets
import EmojiMoney from './../../assets/images/emoji-money.svg'

const SeuDinheiroValeMuito = () => {
  return (
    <section id='seu-dinheiro-vale-muito' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h2 className='fs-24 fs-md-40 lh-30 text-orange--base fw-600 pb-4'>
              <span className='d-block d-md-inline'>Aqui seu dinheiro</span> vale muito <img src={EmojiMoney} alt='Emojy Money' className='mb-2' />
            </h2>
            <p className='fs-12 lh-14 fs-md-18 fw-600 pb-4 text-gray--dark'>Conheça as possibilidades de portabilidades que o Inter tem pra você.</p>
          </div>
          <Options>
            <div className='d-flex grid-hidden pb-3 fw-600 justify-content-center align-items-center text-orange--base text-center pl-lg-3'>
              <ScrollTo
                to='#portabilidade-de-numero'
                section='dobra_02'
                sectionName='Aqui seu dinheiro vale muito'
                elementName='Portabilidade de Número'
              ><b className='d-block'>Portabilidade</b> de Número
              </ScrollTo>
              <ScrollTo
                to='#portabilidade-de-salario'
                section='dobra_02'
                sectionName='Aqui seu dinheiro vale muito'
                elementName='Portabilidade de Salário'
              ><b className='d-block'>Portabilidade</b> de Salário
              </ScrollTo>
              <ScrollTo
                to='#portabilidade-de-investimentos'
                section='dobra_02'
                sectionName='Aqui seu dinheiro vale muito'
                elementName='Portabilidade de Investimentos'
              ><b className='d-block'>Portabilidade</b> de Investimentos
              </ScrollTo>
              <ScrollTo
                to='#portabilidade-de-previdencia-privada'
                section='dobra_02'
                sectionName='Aqui seu dinheiro vale muito'
                elementName='Portabilidade de Previdência Privada'
              ><b className='d-block'>Portabilidade de</b> Previdência Privada
              </ScrollTo>
              <ScrollTo
                to='#portabilidade-de-financiamento-imobiliario'
                section='dobra_02'
                sectionName='Aqui seu dinheiro vale muito'
                elementName='Portabilidade de Financiamento Imobiliário'
              ><b className='d-block'>Portabilidade de</b> Financiamento Imobiliário
              </ScrollTo>
              <ScrollTo
                to='#portabilidade-de-emprestimo-consignado'
                section='dobra_02'
                sectionName='Aqui seu dinheiro vale muito'
                elementName='Portabilidade de Empréstimo Consignado'
              ><b className='d-block'>Portabilidade de</b> Empréstimo Consignado
              </ScrollTo>
              <ScrollTo
                to='#portabilidade-de-maquininha-de-cartao'
                section='dobra_02'
                sectionName='Aqui seu dinheiro vale muito'
                elementName='Portabilidade de Maquininha de cartão'
              ><b className='d-block'>Portabilidade de</b> Maquininha de cartão
              </ScrollTo>
            </div>
          </Options>
        </div>
      </div>
    </section>
  )
}

export default SeuDinheiroValeMuito
