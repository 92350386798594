import React, { useState, useEffect } from 'react'
import LogoMudaProInter from 'src/pages/portabilidade/assets/images/logo-muda-pro-inter.svg'
import QuizPortabilityForm from './QuizPortabilityForm'
import QuizPortabilityResult from './QuizPortabilityResult'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import './QuizPortability.scss'

type QuizPortabilityProps = {
  handleModals: Function;
}

const WIDTH_MD = 768

const QuizPortability = ({ handleModals }: QuizPortabilityProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ active, setActive ] = useState(false)
  const [ values, setValues ] = useState([])
  const [ result, setResult ] = useState(false)
  const content = values.length > 0 && result ? <QuizPortabilityResult handleModals={handleModals} values={values} /> : <QuizPortabilityForm values={values} setValues={setValues} setResult={setResult} />

  useEffect(() => {
    if (window.innerWidth < WIDTH_MD) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [ result ])

  return (
    <div className={`quiz-portability ${active ? 'active' : ''}`}>
      <div className='row justify-content-center align-items-center py-5'>
        <div className='quiz-portability__text col-12 col-xl-10 text-center pb-4 pt-lg-5 mt-lg-3'>
          <img className='quiz-portability__logo' src={LogoMudaProInter} alt='Logo muda sua portabilidade pro Banco Inter' />
          <h1 className='text-white fs-24 fs-md-60 fw-600 lh-29 lh-md-87 pt-5 mt-4 mt-md-0 mb-4 mb-md-5 mb-lg-5'>
            <span className='d-block d-xl-none'><span className='d-lg-block d-xl-inline'>Você está economizando </span><span className='d-lg-block d-xl-inline'><span className='d-md-block d-lg-inline'>ou perdendo</span> dinheiro?</span></span>
            <span className='d-none d-xl-block'>Você está<span className='d-xl-block'> economizando</span> ou perdendo dinheiro?</span>
          </h1>
          <p className='fs-14 fs-md-24 fs-md-20 lh-17 lh-md-30 text-white pb-2 pb-md-5'>
            <span className='d-block d-md-inline'>Responda o quiz e veja se tem feito boas </span><span className='d-md-block d-lg-inline'>escolhas com seu dinheiro.</span>
          </p>
          <button
            onClick={() => {
              setActive(true)
              sendDatalayerEvent({
                section: 'dobra_01',
                section_name: 'Você está economizando ou perdendo dinheiro?',
                element_action: 'click button',
                element_name: 'Quero descobrir',
              })
            }}
            className='bt btn-lg fw-600 fs-17 button-cta d-block mx-auto'
          >
            Quero descobrir
          </button>
        </div>
      </div>
      <div className='quiz-portability__content justify-content-center bg-white'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-8'>
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuizPortability
