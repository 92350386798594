import React from 'react'
import Img from 'gatsby-image'

import Icon from 'src/components/UI/MarkdownIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import usePageQuery from './../../pageQuery'

const PortabilidadeMaquininhaCartao = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='portabilidade-de-maquininha-de-cartao' className='bg-gray py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 px-0 pl-lg-3'>
            <h2 className='fs-24 lh-29 lh-lg-48 fs-lg-40 fw-600 text-orange--base mb-4'> <span className='d-block'>Portabilidade de</span> Maquininha de <span className='d-lg-block'>cartão</span></h2>
            <p className='fs-16 fs-lg-18 lh-19 lh-lg-22 d-block d-lg-none'>
              Sua Conta Digital é PJ? Então já <span className='d-block d-md-inline'>pode pedir a transferência do seu</span><span className='d-block d-md-inline'> domicílio da credenciadora pro</span> Inter.
            </p>
            <p className='fs-16 fs-lg-18 lh-19 lh-lg-22 d-none d-lg-block'>
              Sua Conta Digital é PJ? Então já pode pedir a <span className='d-lg-block d-xl-inline'>transferência do seu domicílio da</span> credenciadora pro Inter:
            </p>
            <div className='row mt-4 mt-lg-5 align-items-center'>
              <div className='col-12 col-lg-6'>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Antecipação de <span className='d-block'>recebíveis com as</span> melhores taxas</p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'><span className='d-block d-lg-inline'>Até 100 TED's  e boletos</span> gratuitos<span className='d-lg-block d-xl-inline'> por mês</span></p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'><span className='d-block'>Saques gratuitos</span> ilimitados</p>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Receba seus <span className='d-block d-lg-inline d-xl-block'>pagamentos de cartão na</span><span className='d-block d-lg-inline d-xl-block'> conta digital 100%</span><span className='d-lg-block'> gratuita</span></p>
                </div>
                <div className='d-flex align-items-center mb-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'><span className='d-block d-lg-inline'>Zero taxa de manutenção</span> da conta</p>
                </div>
              </div>
              <div className='col-12 mt-4 d-none d-lg-block text-center'>
                <button
                  className='fw-600 btn-lg btn--outline btn--orange mb-4'
                >
                  <a
                    href='https://inter.co/empresas/conta-digital/pessoa-juridica/'
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_09',
                        section_name: 'Portabilidade de Maquininha de cartão',
                        element_action: 'click button',
                        element_name: 'Quero mudar pro Inter!',
                        redirect_url: 'https://inter.co/empresas/conta-digital/pessoa-juridica/',
                      })
                    }}
                  >
                    Quero mudar pro Inter!
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-6 pt-2 pt-md-5 pb-3 py-lg-0 px-lg-0'>
            <Img fluid={data.portabilidadeDeDomicilioBancario.fluid} alt='Portabilidade de Domicílio Bancário' />
          </div>
          <div className='col-12 col-lg-6 mt-3 px-3 mt-md-2 text-center d-lg-none'>
            <button
              className='fw-600 btn-lg btn--outline btn--orange mb-4'
            >
              <a
                href='https://inter.co/empresas/conta-digital/pessoa-juridica/'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_09',
                  section_name: 'Portabilidade de Maquininha de cartão',
                  element_action: 'click button',
                  element_name: 'Quero mudar pro Inter!',
                  redirect_url: 'https://inter.co/empresas/conta-digital/pessoa-juridica/',
                })
                }}
              >
                Quero mudar pro Inter!
              </a>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PortabilidadeMaquininhaCartao
