import React from 'react'
import Img from 'gatsby-image'
import Icon from 'src/components/UI/MarkdownIcon'

import usePageQuery from './../../pageQuery'

import { Button } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type modalProps = {
  setPortabilityFormOpen: Function;
}

const PortabilidadeEmprestimoConsignado = ({ setPortabilityFormOpen }: modalProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='portabilidade-de-emprestimo-consignado' className='py-5 pt-md-4 pb-md-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-4 col-lg-6 d-none d-lg-block'>
            <Img fluid={data.portabilidadeDeEmprestimoConsignado.fluid} alt='Portabilidade de Empréstimo Consignado' />
          </div>
          <div className='order-md-2 col-12 col-md-6 px-0'>
            <h2 className='fs-24 lh-29 fs-lg-40 lh-lg-48 fw-600 text-orange--base mb-4'><span className='d-block d-lg-inline d-xl-block'>Portabilidade de </span> Empréstimo Consignado</h2>
            <p className='fs-16 fs-lg-18 lh-19 lh-lg-22'>As melhores taxas para seu crédito! Ideal<span className='d-md-block d-lg-inline'> pra servidores públicos, aposentados e</span> pensionistas:</p>
            <div className='row mt-4 align-items-center'>
              <div className='col-12 col-lg-6'>
                <div className='d-flex align-items-center mb-2 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Melhores taxas de juros</p>
                </div>
                <div className='d-flex align-items-center mb-2 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Cartão consignado</p>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='d-flex align-items-center mb-2 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'><span className='d-lg-block d-xl-inline'>Renegociação</span> aberta <span className='d-block d-lg-inline d-xl-block'>para <span className='d-lg-block d-xl-inline'>condições do</span></span> contrato</p>
                </div>
              </div>
            </div>
            <div className='d-none d-lg-block order-md-3 col-12 mt-3 pr-4 text-center'>
              <Button
                onClick={() => {
                  setPortabilityFormOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_08',
                    section_name: 'Portabilidade de Empréstimo Consignado',
                    element_action: 'click button',
                    element_name: 'Quero mudar pro Inter!',
                  })
                }}
                className='btn-lg btn--outline  fw-600'
              >
                Quero mudar pro Inter!
              </Button>
            </div>
          </div>
          <div className='order-md-1 col-md-6 pt-2 pb-3 d-lg-none'>
            <Img fluid={data.portabilidadeDeEmprestimoConsignado.fluid} alt='Portabilidade de Empréstimo Consignado' />
          </div>
          <div className='d-block d-lg-none order-md-3 col-12 mt-3 pr-4 text-center'>
            <Button
              onClick={() => {
                setPortabilityFormOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_08',
                  section_name: 'Portabilidade de Empréstimo Consignado',
                  element_action: 'click button',
                  element_name: 'Quero mudar pro Inter!',
                })
              }}
              className='btn-lg btn--outline fw-600'
            >
              Quero mudar pro Inter!
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PortabilidadeEmprestimoConsignado
