import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Container = styled.div`
  background: ${white};
  border-radius: 0;
  min-height: 100vh;
  padding: 10px 0 0 0;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    border-radius: 10px;
    max-width: 600px;
    min-height: 540px;
    top: 15vh;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 960px;
    top: 20vh;
  }

  .btn-quero-mudar {
    max-width: 230px;
  }
`

export const Smartphone = styled.figure`
  bottom: 35px;
  position: absolute;
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
`
