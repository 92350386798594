import React from 'react'
import Img from 'gatsby-image'
import Icon from 'src/components/UI/MarkdownIcon'

import usePageQuery from './../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const PortabilidadeFinanciamentoImobiliario = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='portabilidade-de-financiamento-imobiliario' className='bg-gray py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6 pt-lg-3 px-0 pl-lg-3'>
            <h2 className='fs-22 lh-27 fs-md-24 lh-md-29 fs-lg-40 lh-lg-48 fw-600 text-orange--base mb-4'><span className='d-block d-lg-inline'>Portabilidade de</span> Financiamento<span className='d-lg-block d-xl-inline'> Imobiliário</span></h2>
            <p className='fs-16 fs-lg-18 lh-19 lh-lg-22'>
              <span className='d-xl-block'>Reduza as parcelas do seu financiamento e conquiste seu</span> sonho <span className='d-block d-md-inline'>da casa própria com mais tranquilidade:</span>
            </p>
            <div className='row mt-4 mt-lg-5 align-items-center'>
              <div className='col-12 col-lg-6'>
                <div className='d-flex align-items-center mb-2 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>
                    <span className='d-block d-xl-none'><span className='d-lg-block d-xl-inline'>Renegociação das</span> taxas de<span className='d-md-block d-lg-inline'> juros</span></span>
                    <span className='d-none d-xl-block'>Renegociação das taxas <span className='d-xl-block'>de juros</span></span>
                  </p>
                </div>
                <div className='d-flex align-items-center mb-2 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'><span className='d-lg-block d-xl-inline'>Economia nas</span> parcelas<span className='d-md-block d-lg-inline'> mensais</span></p>
                </div>
                <div className='d-flex align-items-center mb-3 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'>Maior flexibilidade no<span className='d-md-block'> prazo para <span className='d-lg-block d-xl-inline'> pagamento</span></span></p>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='d-flex align-items-center mb-3 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'><span className='d-lg-block'>Sem venda casada: </span><span className='d-md-block d-lg-inline d-xl-block'><span className='d-lg-block d-xl-inline'>contrate os produtos</span>  que</span> quiser</p>
                </div>
                <div className='d-flex align-items-center pr-2 mb-3 mb-lg-4'>
                  <div className='pb-1'><Icon width='20' height='20' icon='check' directory='layout' /></div>
                  <p className='fs-16 fs-lg-18 ml-2 mb-0 lh-19 lh-lg-22'><span className='d-lg-block'>Processo gratuito para</span><span className='d-md-block d-lg-inline d-xl-block'><span className='d-lg-block d-xl-inline'> saldo devedor a partir</span> de</span> R$ 100 mil</p>
                </div>
              </div>
              <div className='col-12 mt-4 d-none d-lg-block text-center  text-lg-right'>
                <a
                  className='fw-600 btn-lg btn--outline btn--orange mb-4'
                  href='https://imobiliario.inter.co/portabilidade-financiamento?campanha=site-pf&_gl=1*1f82hbg*_gcl_aw*R0NMLjE1ODc0MDc0MTAuQ2p3S0NBandrUFgwQlJCS0Vpd0E3VEh4aUg0QndmNkdGanpXZENsNDZrbWpZVE5XRDFLYWFOLUdhREpkM3hIbzl6RVBrV3dnbk9kX2lCb0NsMndRQXZEX0J3RQ..&_ga=2.133405128.779704780.1589806728-272334882.1564078060&_gac=1.120268154.1587407468.CjwKCAjwkPX0BRBKEiwA7THxiH4Bwf6GFjzWdCl46kmjYTNWD1KaaN-GaDJd3xHo9zEPkWwgnOd_iBoCl2wQAvD_BwE#/' target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_07',
                      section_name: 'Portabilidade de Financiamento Imobiliário',
                      element_action: 'click button',
                      element_name: 'Quero mudar pro Inter!',
                      redirect_url: 'https://imobiliario.inter.co/portabilidade-financiamento?campanha=site-pf&_gl=1*1f82hbg*_gcl_aw*R0NMLjE1ODc0MDc0MTAuQ2p3S0NBandrUFgwQlJCS0Vpd0E3VEh4aUg0QndmNkdGanpXZENsNDZrbWpZVE5XRDFLYWFOLUdhREpkM3hIbzl6RVBrV3dnbk9kX2lCb0NsMndRQXZEX0J3RQ..&_ga=2.133405128.779704780.1589806728-272334882.1564078060&_gac=1.120268154.1587407468.CjwKCAjwkPX0BRBKEiwA7THxiH4Bwf6GFjzWdCl46kmjYTNWD1KaaN-GaDJd3xHo9zEPkWwgnOd_iBoCl2wQAvD_BwE#/',
                    })
                  }}
                >
                  Quero mudar pro Inter!
                </a>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-5 col-xl-6 pt-2 pt-md-5 pt-lg-0 pb-3 pl-xl-5'>
            <Img fluid={data.portabilidadeDeFinanciamento.fluid} alt='Portabilidade de Financiamento Imobiliário' />
          </div>
          <div className='col-12 col-lg-6 mt-3 mt-md-5 text-center d-lg-none'>
            <a
              className='fw-600 btn-lg btn--outline btn--orange mb-4'
              href='https://imobiliario.inter.co/portabilidade-financiamento?campanha=site-pf&_gl=1*1f82hbg*_gcl_aw*R0NMLjE1ODc0MDc0MTAuQ2p3S0NBandrUFgwQlJCS0Vpd0E3VEh4aUg0QndmNkdGanpXZENsNDZrbWpZVE5XRDFLYWFOLUdhREpkM3hIbzl6RVBrV3dnbk9kX2lCb0NsMndRQXZEX0J3RQ..&_ga=2.133405128.779704780.1589806728-272334882.1564078060&_gac=1.120268154.1587407468.CjwKCAjwkPX0BRBKEiwA7THxiH4Bwf6GFjzWdCl46kmjYTNWD1KaaN-GaDJd3xHo9zEPkWwgnOd_iBoCl2wQAvD_BwE#/' target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_07',
                  section_name: 'Portabilidade de Financiamento Imobiliário',
                  element_action: 'click button',
                  element_name: 'Quero mudar pro Inter!',
                  redirect_url: 'https://imobiliario.inter.co/portabilidade-financiamento?campanha=site-pf&_gl=1*1f82hbg*_gcl_aw*R0NMLjE1ODc0MDc0MTAuQ2p3S0NBandrUFgwQlJCS0Vpd0E3VEh4aUg0QndmNkdGanpXZENsNDZrbWpZVE5XRDFLYWFOLUdhREpkM3hIbzl6RVBrV3dnbk9kX2lCb0NsMndRQXZEX0J3RQ..&_ga=2.133405128.779704780.1589806728-272334882.1564078060&_gac=1.120268154.1587407468.CjwKCAjwkPX0BRBKEiwA7THxiH4Bwf6GFjzWdCl46kmjYTNWD1KaaN-GaDJd3xHo9zEPkWwgnOd_iBoCl2wQAvD_BwE#/',
                })
              }}
            >
              Quero mudar pro Inter!
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PortabilidadeFinanciamentoImobiliario
